const firebaseConfig = {
  projectId: "charlie-and-the-chairman",
  appId: "1:480206885758:web:3030b73982271edac7192f",
  databaseURL:
    "https://charlie-and-the-chairman-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "charlie-and-the-chairman.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyAV8spsZJ6hsl53sgjxNmfqPK_tsoSOKTw",
  authDomain: "charlie-and-the-chairman.firebaseapp.com",
  messagingSenderId: "480206885758",
  measurementId: "G-11JZGEXEHE",
};

export default firebaseConfig;
